import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import Logo1 from '../../assets/img/bimov/benefit-1.svg'
import Logo2 from '../../assets/img/bimov/benefit-2.svg'
import Logo3 from '../../assets/img/bimov/benefit-3.svg'
import Logo4 from '../../assets/img/bimov/benefit-4.svg'
import Logo5 from '../../assets/img/bimov/benefit-5.svg'
import Logo6 from '../../assets/img/bimov/benefit-6.svg'

const Benefits = () => {
  return (
    <Styled>
      <Grid container justifyContent="center" spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={12}>
          <div className='main-title'>
            Beneficios
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className='benefit'>
            <Logo1 />
            <div  className='title text'>Ahorro Económico</div>
            <div className='text'> 
              Administras y almacenas datos en una plataforma en la nube (OPEX), bajando costos.
            </div> 
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className='benefit'>
            <Logo2 />
            <div  className='title text'>Ahorro Económico</div>
            <div className='text'>
              Administras y almacenas datos en una plataforma en la nube (OPEX), bajando costos.
            </div> 
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className='benefit'>
            <Logo3 />
            <div  className='title text'>Efectividad de los datos</div>
            <div className='text'>
              Información clara y comprensible para tomar decisiones, con acceso desde cualquier dispositivo y lugar. </div> 
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className='benefit'>
            <Logo4 />
            <div  className='title text'>Insights Clave</div>
            <div className='text'>
              Visualizas y analizas datos de manera inteligente, para detectar insights relevantes y tomar decisiones fundamentadas.
            </div> 
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className='benefit'>
            <Logo5 />
            <div  className='title text'>Trazabilidad de la Información</div>
            <div className='text'>
              Sigues los resultados o cambios generados por la implementación de mejoras operativas y/o decisiones estratégicas .
            </div> 
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className='benefit'>
            <Logo6 />
            <div  className='title text'>Datos unificados</div>
            <div className='text'> 
              Unificas las datos de la organización en la misma base de información para gestionar proyectos de forma integrada.
            </div> 
          </div>
        </Grid>
      </Grid>
    </Styled>
  )
}

export default Benefits

const Styled = styled.section`
  max-width: 1200px;
  width: 100%;
  margin-bottom: 320px;
  @media (max-width: 640px){
    margin-bottom: 102px;
  }

  .benefit {
    margin: 24px;
    max-width: 300px;
  }

  .main-title {
    text-align: center;
    color: #0E3238;
    font-family: "Century Gothic";
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 32px;
  }

  .title {
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 8px;
  }

  .text {
    color: #0E3238;
    font-family: "Century Gothic";
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
  }

`
