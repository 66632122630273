import React from 'react'
import styled from 'styled-components'
import ContactForm  from './ContactForm'
import { Grid } from '@material-ui/core'
import LogoBimov from '../../assets/img/bimov/bimov-logo.svg'

const Info = () => {
  return (
    <Styled>
      <Grid container  
      direction="row"
      justifyContent="center"
      spacing={2} 
      className='grid-container'>
        <Grid item container alignContent="flex-start" xs={12} md={6}>
          <Grid item container justifyContent="flex-start" xs={10} className='logo'>
            <LogoBimov className='bimov-logo' />
          </Grid>
          <Grid item container xs={10}>
            <div className='text'>
              <b>BImov</b> es nuestra solución de Business Intelligence personalizable para la visualización de datos de diferentes fuentes, 
              descentralizados y desorganizados, actuales e históricos, y los procesa en tiempo real. 
            </div>
            <div className='text'>
              Convertimos los datos en una ventaja competitiva al conectar, combinar y analizar todo los datos de la compañía generando 
              información confiable para la toma de decisiones estratégicas.
            </div>
          </Grid>
        </Grid>
        <Grid item container justifyContent="center" xs={12} md={6}>
          <div className="contact">
            <ContactForm  page='Bimov' location={'Bimov'} />
          </div>
        </Grid>
      </Grid>
    </Styled>
  )
}

export default Info

const Styled = styled.section`
  max-width: 1200px;
  margin-bottom: 220px;
  @media (max-width: 640px){
    margin-bottom: 156px;
  }

  img {
    @media (max-width: 640px){
      width: 250px;
      height: auto;
    }
  }

  .grid-container {
    width: 100%;
    margin: 0;
  }

  .bimov-logo {
    max-width: 100%;
    height: auto;
  }

  .logo {
    display: flex;
    justify-content: flex-start;
    padding: 0 0 32px 32px;
    @media (max-width: 640px){
      padding: 0 32px;
    }
  }

  .title {
    color: #19ADAE;
    font-family: "Century Gothic";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    padding-left: 32px;

    @media (max-width: 640px){
      text-align: start;
      padding: 16px 32px 0 32px;
      font-size: 20px;
      line-height: 28px;
    }
  }

  .logo-title {
    color: #000000;
    font-family: "Century Gothic";
    font-size: 60px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 71px;
    @media (max-width: 640px){
      font-size: 30px;
      line-height: 35px;
      padding-top: 32px;
    }
  }
  
  .text {
    max-width: 100%;
    color: #0E3238;
    font-family: "Century Gothic";
    font-size: 20px;
    letter-spacing: 0;
    line-height: 25px;
    padding: 16px 0 16px 32px;
  }

  .contact {
    max-width: 100%;
    padding: 32px;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;

    @media (max-width: 900px) {
        margin-left: 0px;
    }

    @media (max-width: 780px) {
      margin: 0px 0px 20px 0px;
      padding: 16px;
      width: 100%;
    }

    @media (max-width: 640px){
      h2 {
        font-size: 20px;
      }
      padding: 16px;
    }

    button {
      color: #006069;
      background-color: #6EFFFF;
    }
 }

  .youtube {
    display: flex;
    justify-content: center;
    width: 100%;

  }

  .iframe-youtube {
    margin: 0;
    z-index: 10;
    border-radius: 20px;
    border: 5px solid white;

    @media (max-width: 640px){
      width: 100vw;
      border: none;
      border-radius: 0;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
`

