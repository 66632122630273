import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

const Char = () => {
  return (
    <Styled>
      <Grid container justifyContent='center' spacing={5}>
        <Grid item container justifyContent="center" xs={12} md={10}>
          <div className='card'>
            <div className='card-title'>Características</div>
            <ul className='dot-list'>
              <li>
                <b>Solución en la nube (SaaS) para una  importante reducción de costos</b><br />
                Almacenamiento y procesamiento de datos.
              </li>
              <li>
               <b>Alianzas estratégicas</b><br />
                Microsoft -Azure + Power BI- y Microstrategy, entre otros.
              </li>
              <li>
               <b>Solución a medida de cada negocio</b><br />
                Ecosistema tecnológico para desplegar capacidades analíticas sobre datos estructurados/no estructurados.
              </li>
              <li>
               <b>Experiencia de usuario amigable</b><br />
                Interface y diseño de tableros para las diversas audiencias del negocio.
              </li>
              <li>
               <b>Definición de KPI y métricas estratégicas</b><br />
                Proceso de descubrimiento de indicadores estratégicos y trazabilidad de los datos, para responder a situaciones del negocio que quieran conocer.
              </li>
              <li>
                <b>Equipo técnico multidisciplinario certificado</b><br />
                Profesionales en UX/UI, desarrollo e implementación con experiencia y conocimiento técnico avalados.
              </li>
            </ul>
          </div>
        </Grid>
      </Grid>
    </Styled>
  )
}

export default Char

const Styled = styled.section`
  max-width: 1200px;
  width: 100%;
  margin-bottom: 180px;
  @media (max-width: 640px){
    margin-bottom: 80px;
  }

  .card {
    height: fit-content;
    padding: 27px;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    z-index: 10;
    @media (max-width: 640px){
      margin: 16px;
      padding: 16px;
    }
  }

  .card-title {
    color: #0E3238;
    text-align: center;
    font-family: "Century Gothic";
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 30px;
  }

  .dot-list {
    list-style: none;
    margin-top: 16px;
    color: #000000;
    font-family: "Century Gothic";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;

    li {
      margin-bottom: 24px;
      &:before {
        content: "•";
        color: #FF0051;
        font-weight: bold;
        display: inline-block;
        width: 1.2em;
        margin-left: -1.2em;
      }
    }
  }

  .check-list {
    list-style: none;
    margin: 32px;
    margin-bottom: 32px;
    color: #017884;
    font-family: "Century Gothic";
    font-size: 21px;
    letter-spacing: 0;
    line-height: 28px;

    li {
        &:before {
        content: "✓";
        color: #017884;
        font-weight: bold;
        display: inline-block;
        width: 1.2em;
        margin-left: -1.2em;
        font-family: FontAwesome;
        font-size: 20px;
      }
    }
  }
`
