import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import LogoMicrosoft from '../../assets/img/partners/microsoft-logo.svg'
import LogoAWS from '../../assets/img/partners/logo_aws'
import LogoGoogle from '../../assets/img/partners/google-logo.svg'
import LogoSAP from '../../assets/img/partners/logo-sap.svg'
import LogoPowerBi from '../../assets/img/partners/bi-logo.svg'
import LogoAzure from '../../assets/img/partners/azure-logo.svg'
import LogoSalesforce from '../../assets/img/partners/Logo_Salesforce.svg'


const Partners = () => {
  return (
    <Styled>
      <Grid container  
      direction="row"
      justifyContent="center"
      spacing={0} 
      className='grid-container'>
        <Grid item xs={12}>
          <div align='center' className='title'>
            Alianzas tecnológicas globales
          </div>
        </Grid>
        <Grid item xs={10} className='logos'>
          <div className='grid-logo'>
            <div>
              <LogoMicrosoft />
            </div>
            <div>
              <LogoAWS />
            </div>
            <div>
              <LogoGoogle />
            </div>
            <div>
              <LogoSAP />
            </div>
          </div>
        </Grid>
        <Grid item xs={10} className='logos'>
          <div className='grid-logo'>
            <div>
              <LogoAzure />
            </div>
            <div>
              <LogoPowerBi />
            </div>
            <div>
              <LogoSalesforce />
            </div>
          </div>
        </Grid>
      </Grid>
    </Styled>
  )
}

export default Partners

const Styled = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 60px;

  @media screen and (max-width: 600px){
    padding-bottom: 22px;
  }

  .grid-container {
    width: 100%;
    max-width: 1200px;
    margin: 0;
  }

  .logos {
    display: flex;
    justify-content: center;
  }

  .title {
    color: #030144;
    font-family: 'Century Gothic';
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 32px;
    @media screen and (max-width: 600px){
      font-size: 20px;
    }
  }

  .mobile-img {
    display: none;
    margin: 0;
    @media screen and (max-width: 600px){
      display:block;
    }
  }

  .web-img {
    margin: 0;
    display: block;
    @media screen and (max-width: 600px){
      display:none;
    }
  }

  .img-logo {
    margin: 0;
  }

  .grid-logo {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    div {
      padding: 16px;
      svg {
        max-width: 100%;
      }
    }
    @media screen and (max-width: 600px){
      flex-direction: column;
    }
  }
`
